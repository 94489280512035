'use client';
import React, { PropsWithChildren, Suspense, useMemo } from 'react';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/src/components/ui/sheet';
import { useCreatorPreviewSheetSuspenseQuery } from '@/src/graphql/__generated__/apollo.tsx';
import { AsyncApolloWrapper } from '@/src/components/apollo/async-apollo-wrapper.tsx';
import { ApolloWrapper } from '@/src/components/apollo/apollo-wrapper.tsx';
import { FullsizeErrorBoundary } from '@/src/components/layout/error-boundary/fullsize-error-boundary.tsx';
import { DisplayError } from '@/src/components/layout/error-boundary/display-error.ts';
import { useLocale, useTranslations } from 'next-intl';
import { MediaHorizontalGallery } from '@/src/components/ui/molecules/media-horizontal-gallery.tsx';
import { PreviewInstagramPost } from '@/src/components/creator/preview-instagram-post.tsx';
import OptimizedScraperMedia from '@/src/components/OptimizedScraperMedia.tsx';
import { CityCountryLine } from '@/src/components/ui/atoms/city-country-line.tsx';
import { InstagramLogo } from '@/src/components/ui/atoms/InstagramLogo.tsx';
import ButtonLink from '@/src/components/ui/atoms/ButtonLink.tsx';
import { StatCard } from '@/src/components/ui/molecules/stat-card.tsx';
import { CorneredEdge } from '@/src/components/ui/atoms/cornered-edge.tsx';
import { urls } from '@/src/helpers/urls.ts';
import { Skeleton } from '@/src/components/ui/skeleton.tsx';
import Spinner from '@/src/components/ui/atoms/Spinner.tsx';
import ClientLink from '@/src/components/layout/ClientLink.tsx';
import { useLanguageContext } from '@/src/components/layout/ProvideLanguageContext.tsx';

type CreatorPreviewSheetProps = PropsWithChildren<{
  influencerUuid: string;
}>;

function CreatorPreviewFallback() {
  return (
    <div className="flex h-full flex-col gap-6 p-5">
      <div className="flex flex-row items-center gap-6">
        <Skeleton className="size-[80px] flex-shrink-0 rounded-full" />
        <div className="flex w-full flex-col gap-2">
          <Skeleton className="h-8 w-5/6"></Skeleton>
          <Skeleton className="h-5 w-1/2"></Skeleton>
        </div>
      </div>
      <Skeleton className="h-12 w-full" />
      <div className="mx-auto mt-8 size-1/4">
        <Spinner variant="skeleton" />
      </div>
    </div>
  );
}

export function CreatorPreviewSheet({
  children,
  ...props
}: CreatorPreviewSheetProps) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <span>{children}</span>
      </SheetTrigger>
      <SheetContent>
        <FullsizeErrorBoundary>
          <Suspense fallback={<CreatorPreviewFallback />}>
            <AsyncApolloWrapper>
              <CreatorPreviewSheetContent {...props} />
            </AsyncApolloWrapper>
          </Suspense>
        </FullsizeErrorBoundary>
      </SheetContent>
    </Sheet>
  );
}

function CreatorPreviewSheetContent({
  influencerUuid,
}: CreatorPreviewSheetProps) {
  const trans = useTranslations();
  const languageCode = useLocale();

  const { data } = useCreatorPreviewSheetSuspenseQuery({
    variables: { influencerUuid, languageCode },
  });

  const influencer = data.getSimpleInfluencer;

  if (!influencer?.uuid) {
    throw new DisplayError(
      trans('CREATOR.Influencer kann nicht geladen werden&'),
      'Influencer cannot be loaded',
    );
  }

  const instagramNetwork = useMemo(
    () =>
      data.getSimpleInfluencer?.networks?.find(
        (n) => n?.__typename === 'SimpleInstagramNetwork',
      ),
    [influencerUuid],
  );

  return (
    <div className="@container py-6">
      <div className="px-5">
        <div className="@sm:flex-row @sm:text-left mb-3 flex flex-col items-center gap-6 text-center">
          <div className="size-[80px] flex-shrink-0 overflow-hidden rounded-full bg-zinc-400 ring-4 ring-offset-4">
            <OptimizedScraperMedia
              alt={`profile picture`}
              className="size-full"
              sizes={[80]}
              media={influencer.profile_picture}
            />
          </div>
          <div className="@sm:items-start flex flex-col items-center">
            <span className="text-3xl">{influencer.name}</span>
            <CityCountryLine
              city={influencer.city}
              country={influencer.country}
            />
          </div>
          {/*<ButtonLink*/}
          {/*  className="ml-auto py-6 text-center text-sm"*/}
          {/*  variant="outline"*/}
          {/*  href={creatorUrl({ uuid: influencerUuid })}*/}
          {/*>*/}
          {/*  Analyse*/}
          {/*  <br />*/}
          {/*  öffnen*/}
          {/*</ButtonLink>*/}
        </div>
        {influencer.description && (
          <div className="text-muted-foreground mt-1 text-sm">
            {'"'}
            {influencer.description}
            {'"'}
          </div>
        )}
      </div>
      {instagramNetwork && (
        <>
          <CorneredEdge>
            <div className="bg-card @sm:flex-row @sm:py-2 mb-3 mt-5 flex w-full flex-col items-center gap-x-3 gap-y-2 rounded-md bg-gradient-to-r px-5 py-4">
              <div className="flex flex-row gap-x-2">
                <InstagramLogo className="fill-muted-foreground size-8 flex-shrink-0" />
                <div className="break-all text-xl font-medium">
                  {instagramNetwork.username}
                </div>
              </div>
              <ButtonLink
                href={instagramNetwork.instagram_url}
                className="@sm:ml-auto my-auto flex-shrink-0 text-sm uppercase"
                target="_blank"
                variant="outline"
                clickTrackGtmEvent={{
                  event: 'redirectToInstagramByUsername',
                  username: instagramNetwork.username,
                }}
              >
                <InstagramLogo
                  className="mr-2 size-5 grayscale-[40%]"
                  colorful
                />
                {trans('CREATOR.Zum Instagram')}
              </ButtonLink>
            </div>
          </CorneredEdge>

          <div className="px-5">
            {!!instagramNetwork.bio && (
              <div className="bg-content p-3">{instagramNetwork.bio}</div>
            )}

            <div className="@sm:grid-cols-2 my-3 grid grid-cols-1 gap-3">
              <StatCard
                className="h-16"
                stat={{ label: 'Follower', value: instagramNetwork.follower }}
              />
              <StatCard
                className="h-16"
                stat={{
                  label: 'Engagement',
                  value: `${instagramNetwork.engagement_percent}%`,
                }}
              />
              <StatCard
                className="h-16"
                stat={{
                  label: trans('CREATOR.Follower Wachstum'),
                  value: instagramNetwork.stats_interval
                    ?.follower_growth_percent?.rate
                    ? `${instagramNetwork.stats_interval?.follower_growth_percent?.rate}%`
                    : 'n/a',
                  subline: trans('CREATOR.In einem Monat'),
                }}
              />
              <StatCard
                className="h-16"
                stat={{
                  label: trans('CREATOR.Gewerbliche Beiträge'),
                  value: instagramNetwork.stats_interval?.branded_post_percent
                    ?.rate
                    ? `${instagramNetwork.stats_interval?.branded_post_percent?.rate}%`
                    : 'n/a',
                  subline: trans('CREATOR.In einem Jahr'),
                }}
              />
              <ClientLink
                variant="link"
                target="_blank"
                arrow
                className="text-muted-foreground"
                href={urls.creatorUrl({ uuid: influencerUuid })}
              >
                {trans('CREATOR.Mehr Daten anzeigen')}
              </ClientLink>
            </div>
          </div>

          <CorneredEdge>
            <p className="text-foreground bg-card/30 mb-1 px-5 py-1 text-xl">
              {trans('CREATOR.Letze Posts')}
            </p>
          </CorneredEdge>
          <div className="px-5">
            <p className="text-muted-foreground mb-2">
              {trans('CREATOR.Entdecke die letzten 6 Posts von {name}&', {
                name: influencer.name,
              })}
            </p>
            <MediaHorizontalGallery>
              {instagramNetwork.recent_posts
                ?.filter((post): post is NonNullable<typeof post> => !!post)
                .map((post, i) => (
                  <a
                    href={post.instagram_url}
                    key={i}
                    target="_blank"
                    rel="nofollow"
                  >
                    <div className="h-[550px] w-[340px]">
                      <PreviewInstagramPost post={post} />
                    </div>
                  </a>
                ))}
            </MediaHorizontalGallery>
          </div>
        </>
      )}
    </div>
  );
}
